import React from "react"
import mockupImg from "../../images/mockup.png"
import featForbes from "../../images/featured-forbesLogo.svg"
import featSXSW from "../../images/featured-SXSWLogo.svg"
import featNowThis from "../../images/featured-NowThisLogo.svg"
import featabc7 from "../../images/featured-abc7Logo.svg"
import featnbc from "../../images/featured-nbcLogo.svg"
import appDL from "../../images/appstore-download.svg"
import googleplay from "../../images/googleplay.svg"
import quotes from "../../images/illustrations/quotes.svg"

import step1 from "../../images/illustrations/download-step1.svg"
import step2 from "../../images/illustrations/select-step2.svg"
import step3 from "../../images/illustrations/detect-step3.svg"
import step4 from "../../images/illustrations/connect-step4.svg"
import step5 from "../../images/illustrations/dispatch-step5.svg"
import harvard from "../../images/Harvard.svg"
import stanford from "../../images/Stanford.svg"
import usf from "../../images/USF.svg"
import berkeley from "../../images/Berkeley.svg"
import mit from "../../images/MIT.svg"
import OurStory from "./our-story"

import "./home.css"

// import ContactSteps from "./contact-steps"

class HomePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      submitted: false,
      invalid: false,
    }
    this.handleChange = this.handleChange.bind(this) /* FOR EMAIL CHECK */
  }

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  handleSubmit = event => {
    if (!this.state.email.match(/([a-zA-Z0-9.\-_]+)@([a-z]+).([a-z]+)/)) {
      this.setState({
        invalid: true,
        submitted: false,
      })
    } else {
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: this.encode({ "form-name": "Subscribed", ...this.state }),
      })
        // .then(() => alert("Success!"))
        .catch(error => alert(error))

      // event.preventDefault();
      this.setState({
        email: "",
        submitted: true,
        invalid: false,
      })
    }
    event.preventDefault()
  }

  handleChange = event =>
    this.setState({ [event.target.name]: event.target.value })

  render() {
    return (
      <div className="page-container">
        <div className="home-container">
          <div className="home-leftContainer">
            <h3> 
              FAST&nbsp;&nbsp;|&nbsp;&nbsp;RELIABLE&nbsp;&nbsp;|
              &nbsp;&nbsp;MULTILINGUAL </h3>
            <h1>
              <span style={{color: "#DF4154"}}> Your Quick Message </span>
              <br/>
              <span> to Emergency and Mental Health Services </span>
            </h1>
            <img src={mockupImg} className="mockup-toggle" alt="QR Code to download accesSOS app" />
            <div>
              <a rel='noreferrer' target="_blank" href="https://apps.apple.com/us/app/accessos/id1578050242">
                <img src={appDL} alt='Download from App Store'/>
              </a>
              <a rel='noreferrer' target="_blank" href="https://play.google.com/store/apps/details?id=com.AccesSOS.AccesSOSApp&hl=en_US&gl=US">
                 <img src={googleplay} alt='Download from Google Play Store'/>
              </a>
            </div>
          </div>
          <img src={mockupImg} className="mockup" alt="text911 pwa" />
        </div>
        <div className="banner">
          <div className="banner-title">
            FEATURED IN
          </div>
          <img alt='NBC Logo' src={featnbc}/>
          <img alt='Now This Logo' src={featNowThis}/>
          <img alt='Forbes Logo' src={featForbes}/>
          <img alt='abc7 Logo' src={featabc7}/>
          <img alt='SXSW Logo' src={featSXSW}/>
        </div>

        <div className="contact-steps">
          <h1>
              Contact Emergency Services in a&nbsp;
              <span style={{color: "#DF4154"}}>Few Taps</span>
          </h1>
          <div className="contact-list">
              <div className="list-item">
                  <img alt='' src={step1}/>
                  <h3>
                      <span style={{color: "#4B4B4B"}}>1</span>
                      <span style={{color: "#DF4154"}}>DOWNLOAD</span>
                  </h3>
                  <h4>
                      Download the app
                  </h4>
              </div>
              <div className="list-item">
                  <img alt='' src={step2}/>
                  <h3>
                      <span style={{color: "#4B4B4B"}}>2</span>
                      <span style={{color: "#DF4154"}}>SELECT</span>
                  </h3>
                  <h4>
                    Select what help you need
                  </h4>
              </div>
              <div className="list-item">
                  <img alt='' src={step3}/>
                  <h3>
                      <span style={{color: "#4B4B4B"}}>3</span>
                      <span style={{color: "#DF4154"}}>DETECT</span>
                  </h3>
                  <h4>
                    GPS pinpoints your exact location
                  </h4>
              </div>
              <div className="list-item">
                  <img alt='' src={step4}/>
                  <h3>
                      <span style={{color: "#4B4B4B"}}>4</span>
                      <span style={{color: "#DF4154"}}>CONNECT</span>
                  </h3>
                  <h4>
                    Connected with emergency services
                  </h4>
              </div>
              <div className="list-item">
                  <img alt='' src={step5}/>
                  <h3>
                      <span style={{color: "#4B4B4B"}}>5</span>
                      <span style={{color: "#DF4154"}}>DISPATCH</span>
                  </h3>
                  <h4>
                    Emergency response dispatched
                  </h4>
              </div>
          </div>
        </div>

        <div className="mission">
          <div className="mission-title">
            <h1>Our Mission</h1>
            <h2>Our goal is to live in a world where emergency help is
              accessible to everyone.
            </h2>
          </div>
          <div className="stats-block">
            <div className="photo-bubble">
            </div>
            <div className="stats-desc">
              <h2>As of December 2023, accesSOS has reached over <span style={{fontWeight: "900"}}>11,300</span> users
               and over <span style={{fontWeight: "900"}}>719,000</span> people through advocacy and awareness 
               campaigns.</h2>
            </div>
          </div>
        </div>

        <div className="built-desc">
          <h1>
            We are a <span>Non-Profit</span><br/> Built by Designers & Engineers
          </h1>
          <div>
            <img alt='Harvard University logo' src={harvard}/>
            <img alt='Stanford University Logo' src={stanford}/>
            <img alt='MIT Logo' src={mit}/>
            <img alt='Berkeley Logo' src={berkeley}/>
            <img alt='University of San Francisco Logo' src={usf}/>
          </div>
        </div>

        <OurStory/>

        {/* TODO: improve mobile view - maybe flex-direction column or swipeable carousel */}
        <div>
          <div className="testimonial-block">
            <div className="testimonial-title">
              <h2>From accesSOS Users</h2>
            </div>
            <div className="testimonial-section">
              <div className="testimonial-box">
                <img className="quotes" src={quotes} alt="Testimonials from accesSOS Users" />
                <div className="testimonial">
                  <p>accesSOS benefits everyone. I've been in situations when I was hiding from an intruder. Even whispering was too loud to get help.</p>
                  <p className="testimonial-source">Cara, New Mexico</p>
                </div>
              </div>
              <div className="testimonial-box">
                <img className="quotes" src={quotes} alt=''/>
                <div className="testimonial">
                  <p>accesSOS is life changing for my sister-in-law because she is non-verbal and accesSOS gives her a voice in an emergency.</p>
                  <p className="testimonial-source">Elena, San Francisco</p>
                </div>
              </div>
              <div className="testimonial-box">
                <img className="quotes" src={quotes} alt=''/>
                <div className="testimonial">
                  <p>accesSOS is very easy to use. Pictures describing the type of the emergency, are very clear and self-explanatory.</p>
                  <p className="testimonial-source">Ian, Berkeley</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
}

export default HomePage
