import React from "react"

import "./our-story.css"

const OurStory = () => (
  <div className="container ourstory-container" id="our-story">
    <h1>Origin Story</h1>
    <h4>
      Founder, Gabriella Wong’s father is Deaf. When he had a medical emergency, he could not call 911 for help. 
      He almost <span>died</span> because of this <span>unequal access</span>.
    </h4>
    <div>
      <iframe 
        width="792" 
        height="446" 
        src="https://www.youtube.com/embed/XZjEDn0tjPw" 
        title="The App Making 911 More Accessible for the Deaf Community" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        allowfullscreen/>
    </div>
  </div>
)

export default OurStory
