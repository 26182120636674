import React from "react"
import blackrock from "../../images/sponsors/blackrock.png"
import fastforward from "../../images/sponsors/fastforward.png"
import github from "../../images/sponsors/github.png"
import google from "../../images/sponsors/google.png"
import pagerduty from "../../images/sponsors/pagerduty.png"
//import thomsonreuters from "../../images/sponsors/thomsonreuters.png"
import berkeley from "../../images/sponsors/ucberkeley.png"
import mit from "../../images/sponsors/mit.png"
import camelback from "../../images/sponsors/camelback.png"
import roddenberry from "../../images/sponsors/roddenberry.png"
import usf from "../../images/sponsors/usanfran.png"
import drk from "../../images/sponsors/drk.png"
import twilio from "../../images/sponsors/twilio.png"
import stv from "../../images/sponsors/standtogether.png"
import CAEndow from "../../images/sponsors/caendowment.png"
import CarlMarie from "../../images/sponsors/cmanderson.png"
import Dartmouth from "../../images/sponsors/dartmouth.png"

import "./supporters.css"

// TODO: Rearrange
const Supporters = () => (
  <div className="container supporters-container">
    <h1> Our Supporters </h1>
    <div className="supporters-titleText">
      {" "}
      Thanks to our funders and individual donors, accesSOS is completely free
      for all to use, 24/7.{" "}
    </div>
    <div className="supporters-sponsorContainer">
      <div>
        {" "}
        <img src={drk} alt="Draper Richards Kaplan Foundation" />{" "}
      </div>
      <div>
        {" "}
        <img src={stv} alt="Stand Together Ventures" />{" "}
      </div>
      <div>
        {" "}
        <img src={twilio} alt="Twilio" />{" "}
      </div>
      <div>
        {" "}
        <img src={roddenberry} alt="Roddenberry Foundation" />{" "}
      </div>
      <div>
        {" "}
        <img src={camelback} alt="Camelback Ventures" />{" "}
      </div>
      <div>
        {" "}
        <img src={fastforward} alt="Fast Forward" />{" "}
      </div>
      <div>
        {" "}
        <img src={blackrock} alt="Black Rock" />{" "}
      </div>
      <div>
        {" "}
        <img src={google} alt="Google" />{" "}
      </div>
      <div>
        {" "}
        <img src={CAEndow} alt="The California Endowment" />{" "}
      </div>
      <div>
        {" "}
        <img src={CarlMarie} alt="Carl and Marie Anderson Charitable Foundation"
        />{" "}
      </div>
      <div>
        {" "}
        <img src={github} alt="Github" />{" "}
      </div>
      <div>
        {" "}
        <img src={pagerduty} alt="Pager Duty" />{" "}
      </div>
      <div>
        {" "}
        <img src={berkeley} alt="UC Berkeley School of Information" />{" "}
      </div>
      <div>
        {" "}
        <img src={Dartmouth} alt="Dartmouth" />{" "}
      </div>
      <div>
        {" "}
        <img src={usf} alt="University of San Francisco" />{" "}
      </div>
      <div>
        {" "}
        <img src={mit} alt="Massachusetts Institute of Technology" />{" "}
      </div>
    </div>
  </div>
)

export default Supporters
